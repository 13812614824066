import React, { useEffect, useState } from "react";
import clsx from 'clsx'
import {
	Button,
	Dialog,
	DialogTitle,
	Divider,
	Badge,
	Typography,
	ListItemText,
	Avatar,
	ListItemAvatar,
	ListItem,
	List,
	DialogActions,
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import MarkdownWrapper from "../MarkdownWrapper";
import { format, isSameDay, parseISO } from "date-fns";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	contactNameWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	lastMessageTime: {
		justifySelf: 'flex-end',
	},
	closedBadge: {
		alignSelf: 'center',
		justifySelf: 'flex-end',
		marginRight: 32,
		marginLeft: 'auto',
	},
	contactLastMessage: {
		paddingRight: 20,
	},
}));

const ListRecentContactTicketsModal = ({ contactId, open, onClose }) => {
	const [tickets, setTickets] = useState([])
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	const searchContactTickets = async (values) => {
		try {
			const { data } = await api.get(`/tickets/contact/${contactId}`)
			setTickets(data)
		} catch (err) {
			toastError(err)
		}
	}

	useEffect(() => {
		searchContactTickets()
	}, [])

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Tickets recentes
				</DialogTitle>

				<List>
					{
						tickets.map(ticket => (
							<React.Fragment key={ticket.id}>
								<ListItem
									dense
									button
									onClick={(e) => {
										window.open(`https://bot.brst.com.br/tickets/${ticket.id}`, "_blank")
									}}
									className={clsx(classes.ticket)}
								>
									<ListItemAvatar>
										<Avatar src={ticket?.contact?.profilePicUrl} />
									</ListItemAvatar>
									<ListItemText
										disableTypography
										primary={
											<span className={classes.contactNameWrapper}>
												<Typography noWrap component="span" variant="body2" color="textPrimary">
													{ticket.contact.name}
												</Typography>
												{ticket.status === 'closed' && (
													<Badge className={classes.closedBadge} badgeContent={'closed'} color="primary" />
												)}
												{ticket.lastMessage && (
													<Typography
														className={classes.lastMessageTime}
														component="span"
														variant="body2"
														color="textSecondary"
													>
														{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
															<>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
														) : (
															<>{format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}</>
														)}
													</Typography>
												)}
												{ticket.whatsappId && (
													<div className={classes.userTag} title={i18n.t('ticketsList.connectionTitle')}>
														{ticket.whatsapp?.name}
													</div>
												)}
											</span>
										}
										secondary={
											<span className={classes.contactNameWrapper}>
												<Typography
													className={classes.contactLastMessage}
													noWrap
													component="span"
													variant="body2"
													color="textSecondary"
												>
													<MarkdownWrapper>{ticket.observation}</MarkdownWrapper>
												</Typography>
											</span>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
							</React.Fragment>
						))
					}
				</List>

				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						{i18n.t("userModal.buttons.cancel")}
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	);
};

export default ListRecentContactTicketsModal;
